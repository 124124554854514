<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <h3 class="mb-5">Importeer Excel-bestand</h3>
        <v-row>
          <v-col class="pa-0" cols="12">
            <v-select
              v-if="auctions"
              v-model="selectedAuctionExcel"
              v-bind:items="auctions"
              label="Selecteer een veiling"
              class="property-filter"
              hide-no-data
              item-value="_id"
              item-text="Data.Name.Value.Nl"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <input :ref="'excelInput'" class="fileInput" type="file" v-on:change="setSelectedExcel($event.target.files)">
            <v-btn @click="$refs['excelInput'].click()" class="mb-5" outlined>Seleceer een Excel-bestand</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <h3 class="mt-3 mb-3" v-if="selectedExcel">{{selectedExcel.name}}</h3>

            <v-btn
              :disabled="!(selectedExcel && selectedAuctionExcel)"
              :loading="uploadingExcel"
              v-on:click="submitExcel(selectedExcel)"
            >
              Uploaden<v-icon right dark>cloud_upload</v-icon>
            </v-btn>

            <v-alert v-if="uploadExcelError.length > 0" class="mt-3" color="error" icon="warning" :value="true">
              {{ uploadExcelError }}
            </v-alert>

            <v-alert type="success" v-if="uploadExcelSuccessMessage.length > 0" class="mt-3" icon="check" :value="true">
              {{ uploadExcelSuccessMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-text>
        <h3 class="mb-5">Importeer Afbeeldingen</h3>
        <v-row>
          <v-col class="pa-0" cols="12">
            <v-select
              v-if="auctions"
              v-model="selectedAuctionImages"
              v-bind:items="auctions"
              label="Selecteer een veiling"
              class="property-filter"
              hide-no-data
              item-value="_id"
              item-text="Data.Name.Value.Nl"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <input :ref="'imagesInput'" class="fileInput" type="file" multiple v-on:change="setSelectedImages($event.target.files)" accept='image/*'>
            <v-btn @click="$refs['imagesInput'].click()" class="mb-5" outlined>Seleceer afbeeldingen</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0" cols="12">
            <h3 class="mt-3 mb-3" v-if="selectedImages">
              <template v-if="selectedImages.length == 1">
                {{selectedImages.length}} afbeelding geselecteerd.
              </template>
              <template v-else>
                {{selectedImages.length}} afbeeldingen geselecteerd.
              </template>
            </h3>

            <v-btn
              :disabled="!(selectedImages && selectedAuctionImages)"
              :loading="uploadingImages"
              v-on:click="submitImages(selectedImages)"
            >
              Uploaden<v-icon right dark>cloud_upload</v-icon>
            </v-btn>

            <v-alert v-if="uploadImagesError.length > 0" class="mt-3" color="error" icon="warning" :value="true">
              {{ uploadImagesError }}
            </v-alert>

            <v-alert type="success" v-if="uploadImagesSuccessMessage.length > 0" class="mt-3" icon="check" :value="true">
              {{ uploadImagesSuccessMessage }}
            </v-alert>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import request from '../../request'

export default {
  data () {
    return {
      auctions: null,
      selectedExcel: null,
      selectedImages: null,
      selectedAuctionExcel: null,
      selectedAuctionImages: null,
      uploadingImages: false,
      uploadingExcel: false,
      uploadExcelError: "",
      uploadImagesError: "",
      uploadExcelSuccessMessage: "",
      uploadImagesSuccessMessage: "",
    }
  },
  created () {
    request.get("/pagecontent/5fa3b4eaae990f3950fe0687", null, (res) => {
      if (res.success) {
        this.auctions = res.data
      }
    })
  },
  methods:{
    setSelectedExcel(files){
      this.selectedExcel = files[0]
    },
    setSelectedImages(files){
      this.selectedImages = Array.prototype.slice.call(files)
    },
    submitExcel(file){
      this.uploadingExcel = true
      this.uploadExcelError = ""

      let formData = new FormData()
      formData.append('file', file)

      request.post("/custom/uploadexcel/?auctionId=" + this.selectedAuctionExcel.$oid, formData, (res) => {
        this.uploadingExcel = false
        this.$refs['excelInput'].value = null

        if (res.success) {
          this.uploadExcelSuccessMessage = res.data
        }else{
          this.uploadExcelError = res.message
        }
      })
    },
    submitImages(files){
      this.uploadingImages = true
      this.uploadImagesError = ""

      let formData = new FormData()
      for(var i = 0; i < files.length; i++)
      {
        formData.append('files', files[i])
      }

      request.post("/custom/uploadimages/?auctionId=" + this.selectedAuctionImages.$oid, formData, (res) => {
        this.uploadingImages = false
        this.$refs['imagesInput'].value = null

        if (res.success) {
          this.uploadImagesSuccessMessage = res.data
        }else{
          this.uploadImagesError = res.message
        }
      })
    },
  }
}
</script>

<style>
</style>
